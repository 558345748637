<template>
  <div class="course-card" @click="handleCardClick">
    <div class="img" :style="{ backgroundImage: `url(${course.cover || ''})` }"></div>
    <div class="content">
      <div class="name">
        {{ course.name }}
      </div>
      <div class="desc">
        {{ course.description }}
      </div>
    </div>
    <div class="footer">
      <div @click.stop="del" style="display: flex;align-items: center;cursor: pointer">
        <Icon type="ios-trash-outline" style="font-size: 22px;margin-right: 4px" />
        <span>删除</span>
      </div>
      <div @click.stop="edit" style="display: flex;align-items: center;cursor: pointer">
        <Icon type="ios-create-outline" style="font-size: 22px;margin-right: 4px" />
        <span>编辑</span>
      </div>
      <Dropdown placement="bottom-end" :transfer="true" trigger="hover" v-if="false">
        <div class="operation" @click.stop="">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-gengduo" />
          </svg>
        </div>
        <DropdownMenu slot="list">
          <DropdownItem name="edit">
            <div @click="edit">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-bianji" />
              </svg>
              编辑
            </div>
          </DropdownItem>
          <DropdownItem name="delete">
            <div @click="del">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-shanchu" />
              </svg>
              删除
            </div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CourseCard',
  props: {
    course: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleCardClick() {
      this.$router.push({
        name: 'courseDetail',
        params: { courseId: this.course.courseId }
      })
    },
    edit() {
      this.$emit('on-edit')
    },
    del() {
      this.$emit('on-del')
    }
  }
}
</script>

<style scoped lang="less">
@import '../../../theme/variables';

.course-card {
  width: 310px;
  height: 350px;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(7, 27, 41, 0.1);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all @default-transition;

  &:hover {
    box-shadow: 0 0 7px 0 rgba(7, 27, 41, 0.3);
  }

  .img {
    width: 100%;
    height: 208px;
    background-color: #ebebeb;
    background-size: cover;
  }

  .content {
    padding: 20px;
    flex: 1 0 auto;

    .name,
    .desc {
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .name {
      font-size: 18px;
      color: #2b343d;
      position: relative;
      padding-right: 20px;

      .icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .desc {
      font-size: 16px;
      color: #bdc3cb;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e5e7ea;
    position: relative;
    padding: 10px 40px;

    .user-info {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      padding: 10px 10px 10px 20px;

      .img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #ebebeb;
        background-size: contain;
      }

      .user-name {
        color: @font-color-content;
        margin-left: 10px;
      }

      .create-time {
        margin-left: 10px;
        color: #bdc3cb;
      }
    }

    .operation {
      font-size: 20px;
      color: #bdc3cb;
      cursor: pointer;
      padding: 10px 20px;
      transition: all @default-transition;

      &:hover {
        background-color: #eeeeee;
      }
    }

    .collect {
      svg {
        display: block;
        font-size: 20px;
        fill: @primary-color;
        cursor: pointer;
        transition: all @default-transition;
      }
    }
  }
}

::v-deep .ivu-dropdown-item {
  color: @font-color-content;
}
</style>
